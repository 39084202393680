.slick-prev, .slick-next,
.swiper-button-next, .swiper-button-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 70px;
    height: 70px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-image: $gradient;
	transition: background-color 0.2s;
	z-index: 2;
	opacity: 0.5;

	@media screen and (max-width: 768px) {
		width: 40px;
    	height: 40px;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		font-size: 0;
		background-image: url(/assets/images/down_arrow.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 35px;
		transform: rotate(90deg);
		filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(322deg) brightness(103%) contrast(101%);
	}
	&:after {
		display: none;
	}
	&:hover,
	&:focus {
		opacity: 1;
		background-position: center;
		background-repeat: no-repeat;
		background-image: $gradient;
	}
	&.swiper-button-disabled {
		opacity: 0;
	}
}
.main-top {
	position: relative;
	background-image: url(/assets/images/main_top.jpg);
	background-repeat: no-repeat;
	background-position: center top ;

	@media screen and (max-width: 1280px) {
		background-size: cover;
	}
	header {
		background-image: none;
	}
}
.top {
	padding-top: 210px;
	padding-bottom: 210px;

	@media screen and (max-width: 1280px) {
		padding-top: 15vh;
		padding-bottom: 10vh;
	}
	@media screen and (max-width: 520px) {
		padding-top: 10vh;
		padding-bottom: 5vh;
	}

	.h2 {
		text-align: left;
	}
	&__title {
		display: block;

		&--right {
			margin-left: 65px;
		}
	}
}
.main-text {
	position: relative;
	max-width: 1270px;
	margin: 0 auto;
	padding-top: 80px;
	padding-bottom: 50px;
	padding-left: 100px;

	@media screen and (max-width: 768px) {
		padding-top: 50px;
		padding-bottom: 30px;
		padding-left: 30px;
	}
	@media screen and (max-width: 480px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	&::after {
		content: '';
		position: absolute;
		top: 80px;
		left: 0;
		width: 13.5px;
		height: calc(100% - 130px);
		background-image: linear-gradient(to bottom, rgb(255,248,233) 0%, rgb(255,147,5) 100%);

		@media screen and (max-width: 768px) {
			width: 10px;
			height: calc(100% - 80px);
		}
		@media screen and (max-width: 480px) {
			height: calc(100% - 60px);
		}
	}
	p {
		max-width: 1055px;
	}
	.check-list {
		margin: 15px 0;
		padding: 0;

		@media screen and (max-width: 414px) {
		    margin: 0;
		}

		li {
			margin-bottom: 15px;
		}
	}
}
.main-shedule {
	display: none;
	padding-bottom: 90px;

	@media screen and (max-width: 1199px) {
		padding-bottom: 50px;
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 30px;
	}
}
.main-shedule-list {
	display: grid;
	grid-template-columns: 1fr 0.5fr 0.5fr;
	grid-gap: 30px;
	padding-top: 10px;

	@media screen and (max-width: 900px) {
		grid-gap: 15px;
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (max-width: 460px) {
		grid-template-columns: 1fr;
	}
	
	&__date {
		position: relative;
		display: block;
		padding-left: 45px;
		font-size: 15px;
		font-weight: 500;
		font-family: $m;

		@media screen and (max-width: 768px) {
			font-size: 14px;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: -8px;
			width: 35px;
			height: 35px;
			background-image: url(/assets/images/calendar_icon.svg);
			background-size: 35px;
			background-position: center;
		}
	}
	&__name {
		display: block;
		font-size: 35px;
		line-height: 1.2;

		@media screen and (max-width: 1480px) {
			font-size: 2vw;
		}
		@media screen and (max-width: 768px) {
			font-size: 20px;
		}
	}
	&__type {
		display: block;
		font-size: 15px;
		font-family: $m;
		text-transform: uppercase;
		line-height: 1.2;

		@media screen and (max-width: 480px) {
			font-size: 14px;
		}
		@media screen and (max-width: 360px) {
			font-size: 13px;
		}
	}
	&__item {
		display: flex;
		flex-direction: column;
		padding-left: 40px;

		@media screen and (max-width: 1280px) {
			padding-left: 20px;
		}

		&--orange-img {
			padding-top: 30px;
			padding-left: 50px;
			padding-bottom: 30px;
			background-image: url(/assets/images/shedule_1.jpg);
			background-size: cover;
			border-radius: 0 0 0 25px;

			@media screen and (max-width: 1280px) {
				padding-left: 30px;
			}
			@media screen and (max-width: 768px) {
				grid-column: 1/-1;
			}

			.main-shedule-list__date {
				color: #fff;

				&::before {
					filter: invert(100%) sepia(2%) saturate(7467%) hue-rotate(314deg) brightness(102%) contrast(108%);
				}
			}
			.main-shedule-list__name {
				max-width: 275px;
				margin: 25px 0 10px;

				@media screen and (max-width: 768px) {
					margin: 15px 0 ;
				}
			}
			.main-shedule-list__type {
				max-width: 230px;
				color: #fff;
			}
		}
		&--decor {
			grid-column: 2/3;
			grid-row: 1/3;
			position: relative;
			padding-top: 45px;
			background-image: url(/assets/images/shedule_2.jpg);
			background-size: cover;
			border: 1px solid $orange;

			@media screen and (max-width: 1280px) {
				padding-top: 30px;
			}
			@media screen and (max-width: 768px) {
				grid-row: auto;
				grid-column: 1/2;
			}
			@media screen and (min-width: 769px) {
				.main-shedule-list__date {
					position: absolute;
					left: -45px;
	    			bottom: 70px;
					transform: rotate(-90deg);
				}
			}
			@media screen and (max-width: 460px) {
				grid-column: 1/-1;
				padding-bottom: 15px;
			}
			.main-shedule-list__name {
				font-size: 25px;

				@media screen and (max-width: 1480px) {
					font-size: 1.5vw;
				}
				@media screen and (max-width: 768px) {
					margin: 15px 0 0;
					font-size: 18px;
				}

				strong {
					display: block;
					font-size: 35px;

					@media screen and (max-width: 1480px) {
						font-size: 2vw;
					}
					@media screen and (max-width: 768px) {
						font-size: 20px;
					}
				}
			}
		}
		&--gray-img {
			grid-column: 3/4;
			grid-row: 1/3;
			padding-top: 50px;
			padding-right: 15px;
			background-color: #eeebe8;
			background-image: url(/assets/images/shedule_3.jpg);
			background-size: cover;

			@media screen and (max-width: 1280px) {
				padding-top: 30px;
			}
			@media screen and (max-width: 768px) {
				grid-row: auto;
				grid-column: 2/3;
				background-position: left bottom;
				background-size: 50%;
				background-repeat: no-repeat;
			}
			@media screen and (max-width: 460px) {
				grid-column: 1/-1;
				background-size: 30%;
			}

			.main-shedule-list__date {
				&::before {
					filter: invert(62%) sepia(33%) saturate(5864%) hue-rotate(359deg) brightness(102%) contrast(105%);
				}
			}
			.main-shedule-list__name {
				margin: 30px 0 330px;
				font-size: 25px;

				@media screen and (max-width: 1480px) {
					font-size: 1.5vw;
				}
				@media screen and (max-width: 1280px) {
					margin-bottom: 20vw;
				}
				@media screen and (max-width: 1080px) {
					margin-bottom: 15vw;
				}
				@media screen and (max-width: 768px) {
					margin: 15px 0 10vw;
					font-size: 18px;
				}
			}
			.main-shedule-list__type {
				margin-top: auto;
				margin-bottom: 35px;
			}
		}
		&--photo {
			position: relative;
			grid-column: 1/2;
			grid-row: 2/4;
			padding-top: 60px;
			padding-left: 58%;
			background-color: #efece9;

			@media screen and (max-width: 1280px) {
				padding-top: 40px;
				padding-left: 53%;
			}
			@media screen and (max-width: 768px) {
				grid-row: auto;
				grid-column: 1/-1;
				min-height: 400px;
				padding-right: 10px;
			}
			@media screen and (max-width: 480px) {
				min-height: 300px;
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 53%;
				height: 100%;
				background-image: url(/assets/images/shedule_4.jpg);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;

				@media screen and (max-width: 1280px) {
					width: 48%;
				}
			}
			.main-shedule-list__name {
				max-width: 275px;
				margin: auto 0;
				padding-top: 20px;
				font-size: 40px;
				line-height: 1.1;

				@media screen and (max-width: 1480px) {
					font-size: 2.5vw;
				}
				@media screen and (max-width: 768px) {
					margin: 15px 0;
					font-size: 19px;
				}

				strong {
					font-size: 55px;

					@media screen and (max-width: 1480px) {
						font-size: 3vw;
					}
					@media screen and (max-width: 768px) {
						font-size: 22px;
					}
				}
			}
			.main-shedule-list__type {
				margin-bottom: 30px;
			}
		}
		&--orange {
			grid-column: 2/4;
			padding-top: 30px;
			padding-right: 30px;
			padding-bottom: 30px;
			background-image: $gradient;
			border-radius: 0 0 40px 0;

			@media screen and (max-width: 1280px) {
				padding-right: 20px;
			}
			@media screen and (max-width: 900px) {
				padding-top: 15px;
				padding-bottom: 15px;
			}
			@media screen and (max-width: 768px) {
				grid-row: auto;
				grid-column: 1/-1;
			}

			.main-shedule-list__date {
				margin-left: auto;
				color: #fff;

				&::before {
					filter: invert(100%) sepia(2%) saturate(7467%) hue-rotate(314deg) brightness(102%) contrast(108%);
				}
			}
			.main-shedule-list__name {
				width: 60%;
				max-width: 340px;
				margin: 0 0 15px;

				@media screen and (max-width: 460px) {
					width: 100%;
					margin: 15px 0;
				}
			}
			.main-shedule-list__type {
				font-size: 12px;
			}
		}
	}
}
.main-decor {
	position: relative;
	padding-top: 60px;
	background-image: url(/assets/images/main_team_top.svg);
	background-repeat: no-repeat;
	background-size: 1024px auto;
	background-position: right top;

	@media screen and (max-width: 1800px) {
		background-size: 50% auto;
	}
	@media screen and (max-width: 900px) {
		padding-top: 30px;
	}
	@media screen and (max-width: 768px) {
		padding-top: 0;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 757px;
		height: 893px;
		background-image: url(/assets/images/main_team_middle.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: left center;

		@media screen and (max-width: 1800px) {
			width: 30%;
		}
		@media screen and (max-width: 480px) {
		    background-image: none;
		}
	}
}
.main-about {
	position: relative;
	background-image: url(/assets/images/main_team.jpg);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: auto 100%;

	@media screen and (max-width: 1800px) {
		background-size: 60% auto;
	}
	@media screen and (max-width: 900px) {
	    background-position: left top;
	    background-size: 55% auto;
	}
	@media screen and (max-width: 600px) {
	    background-position: left top;
	    background-size: auto 400px;
	}
	&::before {
		content: '';
		position: absolute;
		left: 30%;
		bottom: 3%;
		width: 244px;
		height: 251px;
		background-image: url(/assets/images/logo_xs.svg);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;

		@media screen and (max-width: 1480px) {
		    width: 200px;
		    height: 200px;
		    bottom: 10%;
		}
		@media screen and (max-width: 1280px) {
		    display: none;
		}
	}

	&__text {
		max-width: 750px;
		margin-left: auto;
		padding-top: 30px;

		@media screen and (max-width: 1360px) {
			max-width: 55%;
		}
		@media screen and (max-width: 900px) {
		    max-width: 100%;
		}
	}
	&__text-top {
		@media screen and (max-width: 900px) and (min-width: 601px) {
		    max-width: 55%;
		    margin-left: auto;
		}
	}
	p {
		padding: 12px 0;
	}
	.main-about__subtitle {
		padding: 0;
		font-size: 55px;
		text-transform: uppercase;
		line-height: 1.1;

		@media screen and (max-width: 1199px) {
		    font-size: 45px;
		}
		@media screen and (max-width: 768px) {
		    font-size: 35px;
		}
		@media screen and (max-width: 600px) {
		    max-width: 55%;
		    margin: 45px 0 45px auto;
		}
		@media screen and (max-width: 480px) {
		    font-size: 25px;
		}
		@media screen and (max-width: 400px) {
		    font-size: 20px;
		}

		&-left {
			display: block;
			margin-left: 20px;
		}
	}
	.main-about__title {
		padding-top: 60px;
		font-size: 25px;
		text-transform: uppercase;

		@media screen and (max-width: 1170px) {
			padding-top: 30px;
		}
		@media screen and (max-width: 414px) {
		    font-size: 20px;
		}
	}
	.check-list {
		margin: 20px 0 0;
		padding: 0;

		@media screen and (max-width: 414px) {
		    margin: 0;
		}

		li {
			margin-bottom: 30px;
		}
	}
	.main-about__more  {
		display: inline-block;
		margin-top: 60px;
		padding: 20px 50px;
		font-size: 12px;
		border-radius: 30px;

  		@media screen and (max-width: 768px) {
		    margin-top: 30px;
		}
	}
}
.main-team {
	position: relative;
	z-index: 1;
	padding-top: 140px;
	padding-bottom: 90px;

	@media screen and (max-width: 1199px) {
	    padding-top: 100px;
		padding-bottom: 70px;
	}
	@media screen and (max-width: 900px) {
	    padding-top: 80px;
		padding-bottom: 50px;
	}
	@media screen and (max-width: 768px) {
	    padding-top: 30px;
		padding-bottom: 30px;
	}

	&__text {
		max-width: 750px;
		margin-left: auto;
		margin-bottom: 65px;

		@media screen and (max-width: 1360px) {
			max-width: 55%;
		}
		@media screen and (max-width: 900px) {
		    max-width: 100%;
		    margin-bottom: 30px;
		}

		.main-team__title {
			padding: 20px 0;
			font-size: 25px;
			text-transform: uppercase;
		}
	}
	&-list {
		display: grid;
		grid-template-columns: repeat(3,1fr);
		grid-column-gap: 90px;
		grid-row-gap: 30px;
		margin: 0;
		padding: 0;
		list-style: none;
		@media screen and (max-width: 1480px) {
			grid-column-gap: 50px;
		}
		@media screen and (max-width: 1280px) {
			grid-column-gap: 40px;
		}
		@media screen and (max-width: 991px) {
			grid-gap: 20px;
		}
		@media screen and (max-width: 768px) {
			grid-template-columns: repeat(2,1fr);
		}
		@media screen and (max-width: 480px) {
			grid-template-columns: 1fr;
			text-align: center;
		}
	}
	&-item {
		&__photo {
			position: relative;
			display: block;
			margin-bottom: 40px;
			line-height: 0;
			background-image: url(/assets/images/team_item_list_bg.png);
			background-size: cover;
			background-position: left bottom;

			@media screen and (max-width: 768px) {
				margin-bottom: 15px;
			}
		}
		&__name {
			position: absolute;
			left: 35px;
			top: 40px;
			font-size: 25px;
			text-transform: uppercase;
			line-height: 1.1;
			text-align: left;

			@media screen and (max-width: 1280px) {
				left: 15px;
				top: 20px;
			}
			@media screen and (max-width: 1170px) {
				font-size: 20px;
			}
			@media screen and (max-width: 860px) {
				font-size: 18px;
			}

			.main-team-item__name-right {
				display: block;
				margin-left: 10px;
			}
		}
		&__more {
			display: inline-block;
			margin-left: 40px;
			padding: 15px 35px;
			
			@media screen and (max-width: 480px) {
				margin: 0 auto;
			}
		}
	}
	&-slider {
		position: relative;

		&__item {
			padding: 0 35px 10px; 

			@media screen and (max-width: 1600px) {
				padding: 0 20px 10px; 
			}
			@media screen and (max-width: 768px) {
				padding: 0 10px 10px;
			}
		}
		.swiper-button-prev {
			left: -40px;
			transform: translate(0, calc(50% - 90px));

			@media screen and (max-width: 1680px) {
				left: -15px;
			}
		}
		.swiper-button-next {
			right: -40px;
			transform: translate(0, calc(50% - 90px));

			@media screen and (max-width: 1680px) {
				right: -15px;
			}
			&::before {
				transform: rotate(90deg) scale(1,-1);
			}
		}
	}
}
.main-services {
	padding-top: 110px;
	padding-bottom: 110px;
	background-image: 
		url(/assets/images/wave.png),
		linear-gradient(to bottom, transparent 100px, #efece9 100px);
	background-repeat: repeat-x, no-repeat;

	@media screen and (max-width: 1199px) {
	    padding-top: 80px;
		padding-bottom: 80px;
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 30px;
	}

	&-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 70px;
		margin: 0;
		padding: 20px 0 0;
		list-style: none;

		@media screen and (max-width: 1170px) {
			grid-gap: 30px;
		}
		@media screen and (max-width: 768px) {
			grid-gap: 20px;
			padding: 0;
		}
		@media screen and (max-width: 720px) {
			grid-template-columns: repeat(2, 1fr);
		}

		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 290px;
			height: 290px;
			margin: 0 auto;
			font-size: 20px;
			text-transform: uppercase;
			border-radius: 50%;
  			background-color: rgb(255, 255, 255);
  			border: 1px solid #efece9;
  			transition: 0.3s;

  			@media screen and (max-width: 980px) {
				width: 220px;
				height: 220px;
				font-size: 17px;
			}
			@media screen and (max-width: 480px) {
				width: 160px;
				height: 160px;
				font-size: 14px;
			}
			@media screen and (max-width: 360px) {
				width: 100%;
			}

  			&:hover {
  				border-color: $orange;
  			}
		}
		li:nth-child(1) .main-services-list__item-name {
			max-width: 245px;

			@media screen and (max-width: 980px) {
				max-width: 200px;
			}
			@media screen and (max-width: 480px) {
				max-width: 130px;
				font-size: 12px;
			}
		}
		li:nth-child(2) .main-services-list__item-name,
		li:nth-child(3) .main-services-list__item-name {
			max-width: 140px;

			@media screen and (max-width: 980px) {
				max-width: 120px;
			}
			@media screen and (max-width: 980px) {
				max-width: 90px;
			}
		}
		li:nth-child(1) .main-services-list__item {
			background-image: url(/assets/images/circle_decor.svg);
			background-size: 800px;
			background-position: 60% 30%; 
		}
		li:nth-child(2) .main-services-list__item {
			background-image: url(/assets/images/circle_decor.svg);
			background-size: 800px;
			background-position: 92% 20%; 
		}
		li:nth-child(3) .main-services-list__item {
			background-image: url(/assets/images/circle_decor.svg);
			background-size: 842px;
			background-position: 172% 52%;
		}
		li:nth-child(4) .main-services-list__item {
			background-image: url(/assets/images/circle_decor.svg);
			background-size: 842px;
			background-position: 235% -18%;
		}
		li:nth-child(5) .main-services-list__item {
			background-image: url(/assets/images/circle_decor.svg);
			background-position: 28% 14%;
    		background-size: 400px;
		}
		li:nth-child(6) .main-services-list__item {
			background-image: url(/assets/images/circle_decor.svg);
			background-size: 842px;
			background-position: 250% -10%;

			@media screen and (max-width: 980px) {
				background-position: 135% -10%;
			}
		}
		li .main-services-list__item:hover {
			background-image: none;
		}
	}
}

.main-video {
	padding-top: 30px;
	padding-bottom: 70px;

	@media screen and (max-width: 768px) {
		padding-bottom: 30px;
	}

	&-list {
		position: relative;
		margin: 0 -10px 40px;

		.slick-slide {
			padding: 0 35px; 
		}
		@media screen and (max-width: 1600px) {
			margin: 0 0 40px;

			.slick-slide {
				padding: 0 20px; 
			}
		}
		@media screen and (max-width: 768px) {
			.slick-slide {padding: 0 10px;}
		}
		.swiper-button-prev {
			left: -75px;

			@media screen and (max-width: 1740px) {
				left: -40px;
			}
			@media screen and (max-width: 1680px) {
				left: -15px;
			}
		}
		.swiper-button-next {
			right: -75px;

			@media screen and (max-width: 1740px) {
				right: -40px;
			}
			@media screen and (max-width: 1680px) {
				right: -15px;
			}
			&::before {
				transform: rotate(90deg) scale(1,-1);
			}
		}
		.swiper-pagination {
			position: absolute;
    		left: 0;
		    bottom: -40px;
		    padding-left: 30px;
		    text-align: left;

		    @media screen and (max-width: 1600px) {
				padding-left: 20px;
			}
			@media screen and (max-width: 768px) {
				padding-left: 10px;
			}
		}
	}
	video,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.main-video-item {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
	}
	.main-video-play {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-image: url(/assets/images/video_cover_1.jpg);
		background-size: cover;
	}
	.main-video-play__btn {
		display: block;
		width: 87px;
  		height: 87px;
  		margin-top: 87px;
  		margin-bottom: 20px;
		border-radius: 50%;
		border: none;
  		background-color: rgb(26, 26, 24);
  		background-image: url(/assets/images/arrow.png);
  		background-repeat: no-repeat;
  		background-position: center;
  		opacity: 0.2;
  		cursor: pointer;

  		&:hover{
  			opacity: 1;
  		}
  		@media screen and (max-width: 768px) {
			width: 50px;
	  		height: 50px;
	  		margin-top: 50px;
	  		margin-bottom: 15px;
	  		background-size: 20px;
		}
	}
	.main-video-play__name {
		display: block;
		font-size: 25px;
		text-transform: uppercase;

		@media screen and (max-width: 980px) {
			font-size: 18px;
		}
		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}
}